.flip {
  transform: scaleX(-1);
  &.v {
    transform: scaleY(-1);
    &.h {
      transform: scale(-1, -1);
    }
  }
}

.x2 {
  font-size: 200%;
}

.x3 {
  font-size: 400%;
}

.x4 {
  font-size: 600%;
}

.blur {
  filter: blur(5px);
  transition: 0.2s;
  &:hover {
    filter: none;
  }
}

.jelly {
  animation: rubberBand var(--speed, 1s) linear 0s infinite;
}

.tada {
  animation: tada 1s linear 0s infinite;
}

.jump {
  animation: jump 0.75s linear 0s infinite;
}

.bounce {
  animation: bounce 0.75s linear 0s infinite;
}

.shake {
  animation: shake var(--speed, 0.5s) linear 0s infinite;
}

.twitch {
  animation: twitch var(--speed, 0.5s) linear 0s infinite;
}

.rainbow {
  animation: rainbow 1s linear 0s infinite;
}

.spin {
  animation: spin var(--speed, 1.5s) linear 0s infinite;
  &.left {
    animation: spin var(--speed, 1.5s) linear 0s infinite reverse;
  }
  &.alternate {
    animation: spin var(--speed, 1.5s) linear 0s infinite alternate;
  }
  &.x {
    animation: spinX var(--speed, 1.5s) linear 0s infinite;
    &.left {
      animation: spinX var(--speed, 1.5s) linear 0s infinite reverse;
    }
    &.alternate {
      animation: spinX var(--speed, 1.5s) linear 0s infinite alternate;
    }
  }
  &.y {
    animation: spinY var(--speed, 1.5s) linear 0s infinite;
    &.left {
      animation: spinY var(--speed, 1.5s) linear 0s infinite reverse;
    }
    &.alternate {
      animation: spinY var(--speed, 1.5s) linear 0s infinite alternate;
    }
  }
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounce {
  0% {
    transform: scaleX(0.9) scaleY(0.9);
  }
  19% {
    transform: scaleX(1.1) scaleY(1.1);
  }
  48% {
    transform: scaleX(0.95) scaleY(0.95);
  }
  100% {
    transform: scaleX(1) scaleY(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinX {
  0% {
    transform: perspective(128px) rotateX(0deg);
  }
  100% {
    transform: perspective(128px) rotateX(360deg);
  }
}

@keyframes spinY {
  0% {
    transform: perspective(128px) rotateY(0deg);
  }
  100% {
    transform: perspective(128px) rotateY(360deg);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-16px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-8px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0) scale(1, 1);
  }
  25% {
    transform: translateY(-16px) scale(1, 1);
  }
  50% {
    transform: translateY(0) scale(1, 1);
  }
  75% {
    transform: translateY(0) scale(1.5, 0.75);
  }
  100% {
    transform: translateY(0) scale(1, 1);
  }
}

@keyframes twitch {
  0% {
    transform: translate(7px, -2px);
  }
  5% {
    transform: translate(-3px, 1px);
  }
  10% {
    transform: translate(-7px, -1px);
  }
  15% {
    transform: translate(0px, -1px);
  }
  20% {
    transform: translate(-8px, 6px);
  }
  25% {
    transform: translate(-4px, -3px);
  }
  30% {
    transform: translate(-4px, -6px);
  }
  35% {
    transform: translate(-8px, -8px);
  }
  40% {
    transform: translate(4px, 6px);
  }
  45% {
    transform: translate(-3px, 1px);
  }
  50% {
    transform: translate(2px, -10px);
  }
  55% {
    transform: translate(-7px, 0px);
  }
  60% {
    transform: translate(-2px, 4px);
  }
  65% {
    transform: translate(3px, -8px);
  }
  70% {
    transform: translate(6px, 7px);
  }
  75% {
    transform: translate(-7px, -2px);
  }
  80% {
    transform: translate(-7px, -8px);
  }
  85% {
    transform: translate(9px, 3px);
  }
  90% {
    transform: translate(-3px, -2px);
  }
  95% {
    transform: translate(-10px, 2px);
  }
  100% {
    transform: translate(-2px, -6px);
  }
}

@keyframes shake {
  0% {
    transform: translate(-3px, -1px) rotate(-8deg);
  }
  5% {
    transform: translate(0px, -1px) rotate(-10deg);
  }
  10% {
    transform: translate(1px, -3px) rotate(0deg);
  }
  15% {
    transform: translate(1px, 1px) rotate(11deg);
  }
  20% {
    transform: translate(-2px, 1px) rotate(1deg);
  }
  25% {
    transform: translate(-1px, -2px) rotate(-2deg);
  }
  30% {
    transform: translate(-1px, 2px) rotate(-3deg);
  }
  35% {
    transform: translate(2px, 1px) rotate(6deg);
  }
  40% {
    transform: translate(-2px, -3px) rotate(-9deg);
  }
  45% {
    transform: translate(0px, -1px) rotate(-12deg);
  }
  50% {
    transform: translate(1px, 2px) rotate(10deg);
  }
  55% {
    transform: translate(0px, -3px) rotate(8deg);
  }
  60% {
    transform: translate(1px, -1px) rotate(8deg);
  }
  65% {
    transform: translate(0px, -1px) rotate(-7deg);
  }
  70% {
    transform: translate(-1px, -3px) rotate(6deg);
  }
  75% {
    transform: translate(0px, -2px) rotate(4deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(3deg);
  }
  85% {
    transform: translate(1px, -3px) rotate(-10deg);
  }
  90% {
    transform: translate(1px, 0px) rotate(3deg);
  }
  95% {
    transform: translate(-2px, 0px) rotate(-3deg);
  }
  100% {
    transform: translate(2px, 1px) rotate(2deg);
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rainbow {
  0% {
    filter: hue-rotate(0deg) contrast(150%) saturate(150%);
  }
  100% {
    filter: hue-rotate(360deg) contrast(150%) saturate(150%);
  }
}
